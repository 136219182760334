import {
    Component,
    ElementRef,
    Injector,
    OnDestroy,
    OnInit,
    runInInjectionContext,
    ViewChild
} from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { SearchService } from './services/search.service';
import { AutoAnimateDirective, SpinnerComponent } from '@knalgeel/pandora';
import { MapComponent } from '../core/components/map/map.component';
import { MapConfig } from '../types/search/map-config';
import {
    SearchSecondaryNavComponent
} from './components/search-secondary-nav/search-secondary-nav.component';
import { NgIcon } from '@ng-icons/core';
import {
    SearchDetailsComponent
} from './supply/components/search-details/search-details.component';
import {
    SearchSupplyActiveFiltersComponent
} from './supply/components/active-filters/search-supply-active-filters.component';
import {
    SupplyResultTableComponent
} from './supply/components/result-table/supply-result-table.component';
import { ToggleComponent } from '../core/components/inputs/toggle/toggle.component';
import { NgClass } from '@angular/common';
import { debounceTime, distinctUntilChanged, filter, Subject, takeUntil } from 'rxjs';
import { SearchSegment } from './typings/search-segment';
import {
    DownloadAppModalComponent
} from '../pages/home/components/download-app-modal/download-app-modal.component';
import {
    MobileScreenBlockerComponent
} from '../pages/mobile-screen-blocker/mobile-screen-blocker.component';

const MAP_CONFIG: MapConfig = {
    subscriptionKey: '9cvPu9EIxM42cDVi8u7GkVuOk9ZRShGDh76RAxXRKQ4NNB9iFqviJQQJ99BCAC5RqLJDcfSKAAAgAZMPl8kX',
    language: 'nl',
    tileSize: 256,
    tileSetId: 'microsoft.base.road',
    defaultZoom: 7.5,
    xOffset: 25,
    center: {
        lat: 52.379189,
        lng: 4.899431
    }
}

@Component({
    standalone: true,
    imports: [
        RouterOutlet,
        AutoAnimateDirective,
        MapComponent,
        SearchSecondaryNavComponent,
        NgIcon,
        SearchDetailsComponent,
        SearchSupplyActiveFiltersComponent,
        SupplyResultTableComponent,
        SpinnerComponent,
        ToggleComponent,
        NgClass,
        DownloadAppModalComponent,
        MobileScreenBlockerComponent
    ],
    templateUrl: './search-page.component.html',
    styleUrls: [ './search-page.component.scss' ]
})
export class SearchPageComponent implements OnInit, OnDestroy {
    @ViewChild('page', { static: true }) page!: ElementRef;

    protected mapConfig: MapConfig = {
        ...MAP_CONFIG
    };

    // ----------[ Computed ]----------

    private destroy$ = new Subject<void>();

    constructor(
        private readonly route: ActivatedRoute,
        private readonly searchService: SearchService,
        private readonly injector: Injector,
        private readonly router: Router
    ) {
    }

    public ngOnInit() {
        this.initializeStateFromRouteParams();

        this.searchService.isActive.set(true);

        runInInjectionContext(this.injector, () => {
            this.setupStateObservers();
        });
    }

    public ngOnDestroy() {
        this.searchService.isActive.set(false);
        this.searchService.resetFilters();
        this.destroy$.next();
        this.destroy$.complete();
    }

    private initializeStateFromRouteParams() {
        this.route.queryParamMap.pipe(
            takeUntil(this.destroy$)
        ).subscribe(params => {
            this.searchService.set('segment', this.validateSegment(params.get('segment')));
            this.searchService.set('categoryId', this.validateParam(params.get('category')));
        });
    }

    private setupStateObservers() {
        this.searchService.observe('segment')
            .pipe(
                filter(Boolean),
                takeUntil(this.destroy$)
            )
            .subscribe(() => {
                this.searchService.set('categoryId', null);
            });


        this.searchService.observe('categoryId')
            .pipe(
                filter(Boolean),
                takeUntil(this.destroy$)
            )
            .subscribe(() => {
                let route = '';
                switch (this.searchService.select('mode')()) {
                    case 'supply':
                        route = 'aanbod';
                        break
                    case 'demand':
                        route = 'vraag';
                        break
                    case 'companies':
                        route = 'bedrijven';
                        break
                    default:
                        break
                }

                this.router.navigate([ '/', route ], {
                    relativeTo: this.route,
                    queryParams: {
                        segment: null
                    },
                    queryParamsHandling: 'merge',
                    replaceUrl: true
                });
            });

        this.searchService.observerMany([ 'segment', 'categoryId' ])
            .pipe(
                debounceTime(300),
                distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
                takeUntil(this.destroy$)
            )
            .subscribe(([ segment, categoryId ]) => {
                this.setRouteParam({
                    segment: this.validateSegment(segment),
                    category: this.validateParam(categoryId)
                });
            });

    }

    private setRouteParam(params: { [key: string]: string | null }) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            queryParamsHandling: 'merge',
            replaceUrl: true
        });
    }

    private validateParam(value: string | null): string | null {
        return value && value.trim() !== '' ? value.trim() : null;
    }

    private validateSegment(segment: string | null): SearchSegment | null {
        const allowedSegments = [
            'machines-bemand',
            'machines-onbemand',
            'vakmensen',
            'grond-bouwstoffen',
            'materialen'
        ];

        if (segment === null) {
            return null;
        }

        return (allowedSegments.includes(segment) ? segment : null) as SearchSegment | null;
    }

}
