import { AfterViewInit, Component, OnDestroy, OnInit, signal } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { HomepageMapControlsComponent } from '../map-controls/homepage-map-controls.component';
import { AutoAnimateDirective } from '@knalgeel/pandora';
import { MapComponent } from '../../../../core/components/map/map.component';
import { MapConfig } from '../../../../types/search/map-config';
import { MapService } from '../../../../search/services/map.service';
import { SearchSupplyBySegmentGQL, SupplySearchResult } from '../../../../../../graphql/generated';
import { distinctUntilChanged, map, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { SearchSegment } from '../../../../search/typings/search-segment';
import { ModalService } from '../../../../core/services/modal.service';
import { DownloadAppModalComponent } from '../download-app-modal/download-app-modal.component';

@Component({
    selector: 'home-hero-section',
    standalone: true,
    imports: [
        RouterLink,
        HomepageMapControlsComponent,
        AutoAnimateDirective,
        MapComponent
    ],
    templateUrl: './home-page-hero-section.component.html'
})
export class HomePageHeroSectionComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly destroy$ = new Subject<void>();

    private readonly segment: SearchSegment = 'machines-bemand';

    public readonly loading = signal<boolean>(false);
    public readonly search = signal<SupplySearchResult | null>(null);
    public readonly search$ = toObservable(this.search);
    protected results = toSignal(
        this.search$.pipe(
            map(search => search?.hits?.nodes || [])
        ), {
            initialValue: []
        }
    );
    protected results$ = toObservable(this.results);

    protected readonly config: MapConfig = {
        subscriptionKey: '9cvPu9EIxM42cDVi8u7GkVuOk9ZRShGDh76RAxXRKQ4NNB9iFqviJQQJ99BCAC5RqLJDcfSKAAAgAZMPl8kX',
        language: 'nl',
        tileSize: 512,
        tileSetId: 'microsoft.base.road',
        defaultZoom: 9.45,
        xOffset: 0,
        center: {
            lat: 52.379189,
            lng: 5.49431
        },
        scrollDisabled: true
    }

    private readonly fetch$ = new Subject<string | null>();

    constructor(
        private readonly searchSupplyBySegmentGQL: SearchSupplyBySegmentGQL,
        private readonly mapService: MapService,
        private readonly router: Router,
        private readonly modalService: ModalService
    ) {
    }

    public ngAfterViewInit() {
        this.fetch$.next(null);
    }

    public ngOnInit(): void {
        this.fetch$.pipe(
            takeUntil(this.destroy$),
            tap(() => {
                this.loading.set(true);
                this.mapService.clear();
                this.search.set(null);
            }),
            switchMap(() =>
                this.searchSupplyBySegmentGQL
                    .watch({ input: { slug: this.segment, filters: [] } })
                    .valueChanges
                    .pipe(distinctUntilChanged())
                    .pipe(map(response => response.data.searchSupplyBySegment))
            ),
            tap(() => this.loading.set(false))
        ).subscribe(search => this.search.set(search as unknown as SupplySearchResult));

        this.results$
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe(results => {

                this.mapService.addMarkers(results.map(result => ({
                    id: result.item!.id,
                    type: 'primary',
                    onClick: () => {
                        this.router.navigate([ '/aanbod', result.item!.encodedId ]);
                    },
                    coordinates: {
                        lat: result.item!.location.geometry.coordinates[1],
                        lng: result.item!.location.geometry.coordinates[0]
                    }
                }))).pipe(
                    takeUntil(this.destroy$)
                ).subscribe();
            });
    }

    public ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    openDownloadModal() {
        const modalId = this.modalService.open({
            component: DownloadAppModalComponent,
            inputs: {
            },
            outputs:{
                closeModal: () => this.modalService.close(modalId)
            }
        });

    }
}
